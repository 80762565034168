import { faEthereum } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelS } from '@sorare/core/src/atoms/typography';
import { Dots } from '@sorare/core/src/atoms/ui/Dots';

import { useEthBalance } from './useEthBalance';

export const EthBalance = () => {
  const { hideBalance, balanceInEth } = useEthBalance();

  return hideBalance ? (
    <Horizontal center>
      <FontAwesomeIcon icon={faEthereum} />
      <Horizontal gap={0.5}>
        <Dots count={3} size="small" />
      </Horizontal>
    </Horizontal>
  ) : (
    <LabelS bold>{balanceInEth}</LabelS>
  );
};
