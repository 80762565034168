/* eslint-disable no-console */
import * as nativeMessages from 'protos/webview/channel/native_messages';
import { StringKeysOf } from 'types';

import {
  IOsMobileAppVersion,
  MobileAppVersion,
  SESSION_STORAGE,
  getValue,
} from './useSessionStorage';

export const ENTER_FROM_ANDROID_MARKETPLACE_TAB_PARAM =
  'enterFromAndroidMarketplaceTab';

declare global {
  interface Window {
    MobileAppChannel?: {
      postMessage: (message: any) => void;
    };
    isFlutterInAppWebViewReady?: boolean;
    flutter_inappwebview?: {
      callHandler: (handlerName: string, message: any) => void;
    };
    webkit?: {
      messageHandlers: {
        iosAppChannel: {
          postMessage: (message: any) => void;
        };
      };
    };
  }
}

type messages = StringKeysOf<nativeMessages.ChannelMessage>;

const postMessage = <K extends messages>(
  message: K,
  params?: nativeMessages.ChannelMessage[K]
) => {
  const isMobileApp = getValue(SESSION_STORAGE.mobileApp);
  const jsonMessage = nativeMessages.ChannelMessage.toJSON({
    [message]: params || {},
  } as any);

  if (isMobileApp === 1 || isMobileApp === 2) {
    if (window.MobileAppChannel) {
      window.MobileAppChannel.postMessage(JSON.stringify(jsonMessage));
    } else {
      console.error(
        'MobileAppChannel is not defined, cannot post',
        jsonMessage
      );
    }
  } else if (typeof isMobileApp === 'number' && isMobileApp >= 3) {
    if (window.isFlutterInAppWebViewReady && window.flutter_inappwebview) {
      try {
        window.flutter_inappwebview.callHandler(
          'channelMessageHandler',
          jsonMessage
        );
      } catch {
        // usually a `window.flutter_inappwebview._callHandler is not a function` error
        // really there is not much we can do, the internal `_callHandler` might not be ready or so
      }
    } else if (!window.flutter_inappwebview) {
      console.error(
        'flutter_inappwebview is not defined, cannot call handler',
        jsonMessage
      );
    } else {
      console.error(
        'flutter_inappwebview is not ready, cannot call handler',
        jsonMessage
      );
    }
  } else if (
    typeof isMobileApp === 'string' &&
    window?.webkit?.messageHandlers?.iosAppChannel?.postMessage
  ) {
    window.webkit.messageHandlers.iosAppChannel.postMessage(jsonMessage);
  } else {
    console.error('Not a mobile app, cannot postMessage', jsonMessage);
  }
};

//ANDROID_APP_BUILD_VERSION
const ANDROID_APP_BUILD_VERSION_NATIVE_OAUTH_SIGNUP_THRESHOLD = 178;
const ANDROID_APP_BUILD_VERSION_NATIVE_SEARCH = 181;
const ANDROID_APP_BUILD_VERSION_OPEN_BROWSER = 220;

//IOS_APP_BUILD_VERSION
const IOS_APP_BUILD_VERSION_CAN_USE_POST_MESSAGE_AND_FOR_YOU_THRESHOLDS = {
  [IOsMobileAppVersion.IOS_1]: 238,
  [IOsMobileAppVersion.IOS_GOLD]: 227,
};

const IOS_APP_BUILD_VERSION_CAN_USE_NATIVE_CARD_PAGE = {
  [IOsMobileAppVersion.IOS_1]: 259,
  [IOsMobileAppVersion.IOS_GOLD]: 254,
};

const SLOW_MODE_DEVICE_MEMORY_THRESHOLD = 4;

export const parseMobileApp = (
  isMobileApp: MobileAppVersion | null | undefined
) => {
  const isIosApp =
    typeof isMobileApp === 'string' && isMobileApp.includes('ios');

  const isGoldApp = isIosApp && isMobileApp.includes('gold');

  const isAndroidApp = !!isMobileApp && !isIosApp;
  return { isIosApp, isGoldApp, isAndroidApp };
};

export const useIsMobileApp = () => {
  const isMobileApp = getValue(SESSION_STORAGE.mobileApp);
  const appBuildVersionNumber =
    getValue(SESSION_STORAGE.appBuildVersionNumber) ||
    // locally, always assume we're running the latest build, except when explicitly set
    (process.env.NODE_ENV === 'development' && 9999);
  const googlePlayStoreCountryCode = getValue(
    SESSION_STORAGE.googlePlayStoreCountryCode
  );

  const { isAndroidApp, isGoldApp, isIosApp } = parseMobileApp(isMobileApp);

  // Experimental: detect slow device by checking device memory
  // When `deviceMemory` is not available, this will default to 0
  const slowDevice =
    +('deviceMemory' in navigator && (navigator as any).deviceMemory) <=
    SLOW_MODE_DEVICE_MEMORY_THRESHOLD;

  const nativeOAuthSignup =
    isAndroidApp &&
    appBuildVersionNumber &&
    appBuildVersionNumber >=
      ANDROID_APP_BUILD_VERSION_NATIVE_OAUTH_SIGNUP_THRESHOLD;

  const canUseIosPostMessageAndForYou = !!(
    isIosApp &&
    appBuildVersionNumber &&
    appBuildVersionNumber >=
      IOS_APP_BUILD_VERSION_CAN_USE_POST_MESSAGE_AND_FOR_YOU_THRESHOLDS[
        isGoldApp ? IOsMobileAppVersion.IOS_GOLD : IOsMobileAppVersion.IOS_1
      ]
  );

  const canUseIosNativeCardPage = !!(
    isIosApp &&
    appBuildVersionNumber &&
    appBuildVersionNumber >=
      IOS_APP_BUILD_VERSION_CAN_USE_NATIVE_CARD_PAGE[
        isGoldApp ? IOsMobileAppVersion.IOS_GOLD : IOsMobileAppVersion.IOS_1
      ]
  );

  const canOpenBrowser =
    isAndroidApp &&
    appBuildVersionNumber &&
    appBuildVersionNumber >= ANDROID_APP_BUILD_VERSION_OPEN_BROWSER;

  return {
    isMobileApp: !!isMobileApp,
    isIosApp,
    isAndroidApp,
    googlePlayStoreCountryCode,
    hideBottomNavBar:
      (typeof isMobileApp === 'number' && isMobileApp >= 2) || isIosApp,
    postMessage,
    slowDevice,
    nativeOAuthSignup,
    useNativeSearch:
      isAndroidApp &&
      appBuildVersionNumber &&
      appBuildVersionNumber >= ANDROID_APP_BUILD_VERSION_NATIVE_SEARCH,
    consentTracking: true, // TODO: implement consent tracking natively and forward the value here
    canUseIosPostMessageAndForYou,
    canUseIosNativeCardPage,
    appBuildVersionNumber,
    postOpenCurrentUrlMessage: canOpenBrowser
      ? (cardSlug?: string) =>
          postMessage('openBrowser', {
            // eslint-disable-next-line no-restricted-properties
            url: `${window.location.origin}${window.location.pathname}?${ENTER_FROM_ANDROID_MARKETPLACE_TAB_PARAM}=${appBuildVersionNumber}${cardSlug ? `&card=${cardSlug}` : ''}`,
          })
      : undefined,
  };
};
