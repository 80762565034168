import { Navigate, useLocation } from 'react-router-dom';

import { IS_PRERENDER } from 'config';
import { useQueryString } from 'hooks/useQueryString';

// This component is used to redirect the user to the same page with the `utm_campaign=rotowire` query param
// if the source query param is `rotowire`. This is used to track the traffic from RotoWire ensuring we keep
// control of the UTMs used.
export const RotoWireRedirect = () => {
  const source = useQueryString('source');
  const location = useLocation();

  if (!source || source.toLowerCase() !== 'rotowire' || IS_PRERENDER) {
    return null;
  }

  // we could imagine pass more than `utm_campaign` here
  return <Navigate to={`${location.pathname}?utm_campaign=rotowire`} replace />;
};
