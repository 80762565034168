import { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Backdrop } from 'atoms/loader/Backdrop';

export enum DialogKey {
  news = 'news',
  claimedConversionCredit = 'claimedConversionCredit',
  deliveryItemOrder = 'deliveryItemOrder',
  card = 'card',
  bundledAuction = 'bundledAuction',
  primaryOfferBought = 'primaryOfferBought',
  paypalTransactionId = 'transactionId',
  unblockWallet = 'unblockWallet',
  game = 'game',
  eventType = 'eventType',
  auction = 'auction',
  verifyPhone = 'verifyPhone',
  loanWelcomeScreen = 'loanWelcomeScreen',
  checkout = 'checkout',
}

export const WithDialogs = ({
  children,
  dialogs,
}: {
  children: React.ReactNode;
  dialogs: {
    name: DialogKey;
    element: React.ReactNode;
  }[];
}) => {
  const [searchParams] = useSearchParams();
  const openDialogs = dialogs.filter(({ name }) => !!searchParams.get(name));
  return (
    <>
      {children}
      {openDialogs.map(dialog => (
        <Suspense key={dialog.name} fallback={<Backdrop />}>
          {dialog?.element}
        </Suspense>
      ))}
    </>
  );
};
