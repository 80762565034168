import { ReactNode, Suspense } from 'react';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { lazy } from '@sorare/core/src/lib/retry';

import { InviteFriendsButton } from '../InviteFriendsButton';
import { UserNav } from '../UserNav';

const Notifications = lazy(
  async () => import('components/notification/Notifications'),
  'Notifications'
);

const Root = styled(Horizontal)`
  position: relative;
  justify-content: flex-end;
  border-bottom: 1px solid var(--c-nd-150);
  padding: var(--intermediate-unit) var(--double-and-a-half-unit);
`;
const PageHeader = styled(Horizontal).attrs({ gap: 2 })`
  flex: 1;
  max-width: 100%;
`;
const PageHeaderRightButton = styled(Horizontal).attrs({ gap: 0.5 })`
  margin-left: auto;
`;
const OverflowHiddenWrapper = styled.div`
  overflow: hidden;
`;
const PageHeaderTitle = styled(OverflowHiddenWrapper)`
  width: 100%;
`;

type Props = { extraHeaderButton?: ReactNode };
export const DesktopWebAppHeader = ({ extraHeaderButton }: Props) => {
  const { sport } = useSportContext();

  return (
    <Root>
      <PageHeader>
        <PageHeaderTitle id="page-header-title" />
        <OverflowHiddenWrapper id="page-header-title-breadcrumb" />
        <PageHeaderRightButton id="page-header-right-button" />
      </PageHeader>
      {extraHeaderButton}
      <InviteFriendsButton />
      <Suspense fallback={null}>
        <Notifications />
      </Suspense>
      <UserNav sport={sport} />
    </Root>
  );
};
