import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const ChevronDoubleRightBold = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.48038 2.19076L13.2701 8.00015L7.48038 13.8095L9.08531 15.4144L16.5 8.00015L9.08531 0.585938L7.48038 2.19076ZM1.48 2.19076L7.26973 8.00015L1.48 13.8095L3.08493 15.4144L10.4996 8.00015L3.08493 0.585938L1.48 2.19076Z"
      fill="currentColor"
    />
  </svg>
);
