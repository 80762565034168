import classNames from 'classnames';
import { useState } from 'react';
import styled from 'styled-components';

import { FadeInOut } from '@sorare/core/src/atoms/animations/FadeInOut';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelS } from '@sorare/core/src/atoms/typography';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { MarketCredits } from './MarketCredits';
import { MobileUserCurrenciesDialog } from './MobileUserCurrenciesDialog';
import { MoneyBalances } from './MoneyBalances';
import {
  CurrencyData,
  InGameCurrencyAgreggated,
  useUserCurrencies,
} from './useUserCurrencies';

const Root = styled(Vertical)`
  position: relative;
  width: 100%;
`;
const InGameCurrenciesWrapper = styled(Horizontal)`
  & > :not(:first-child) {
    margin-left: calc(-1 * var(--double-unit));
  }

  @media ${laptopAndAbove} {
    & > :not(:first-child) {
      margin-left: 0;
    }
  }
`;
const InGameCurrencyWrapper = styled(Horizontal).attrs({ gap: 0.5 })`
  background-color: var(--c-black);
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  border-radius: 50%;
  border: 1px solid var(--c-nd-150);
  padding: 0 var(--unit);

  &.expanded {
    border-radius: var(--double-unit);
    width: auto;
  }

  @media ${laptopAndAbove} {
    flex-direction: column;
    justify-content: center;

    flex: 1;
    padding: var(--unit);
    border-radius: var(--unit);
    height: auto;
  }
`;

type Props = {
  expandedCurrencies?: InGameCurrencyAgreggated[];
};
export const UserCurrencies = ({ expandedCurrencies }: Props) => {
  const isLaptopAndAbove = useIsLaptop();
  const { sport } = useSportContext();
  const [mobileDialogOpened, setMobileDialogOpened] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyData | null>(
    null
  );

  const currencies = useUserCurrencies(expandedCurrencies);

  return (
    <>
      <MobileUserCurrenciesDialog
        open={mobileDialogOpened}
        onClose={() => setMobileDialogOpened(false)}
        currencies={currencies}
      />
      <Root
        as={isLaptopAndAbove ? 'div' : 'button'}
        {...(!isLaptopAndAbove
          ? { onClick: () => setMobileDialogOpened(true) }
          : {})}
        onMouseLeave={() => setSelectedCurrency(null)}
      >
        {isLaptopAndAbove ? (
          <FadeInOut>{selectedCurrency?.details({})}</FadeInOut>
        ) : null}
        <InGameCurrenciesWrapper>
          {currencies.map((currency, i) => {
            const { id, icon, expanded, visibleOnSports, amount, onClick } =
              currency;

            return (
              visibleOnSports.includes(sport) && (
                <InGameCurrencyWrapper
                  key={id}
                  as="button"
                  onClick={onClick}
                  className={classNames({
                    expanded: !isLaptopAndAbove && expanded,
                  })}
                  style={{ zIndex: currencies.length - i }}
                  onMouseEnter={() => setSelectedCurrency(currency)}
                >
                  {icon}
                  {(isLaptopAndAbove || expanded) && (
                    <LabelS bold>{amount}</LabelS>
                  )}
                </InGameCurrencyWrapper>
              )
            );
          })}
        </InGameCurrenciesWrapper>
        {isLaptopAndAbove && (
          <>
            <MoneyBalances />
            <MarketCredits sport={sport} />
          </>
        )}
      </Root>
    </>
  );
};
