import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

type Props = {
  color?: Color;
  size?: keyof typeof unitMapping;
  title?: string;
};

export const DropCards = ({ color, size = 2, title }: Props) => {
  return (
    <svg
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: unitMapping[size], height: unitMapping[size] }}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.73634 0.966039C8.5934 0.432572 8.04506 0.11599 7.51159 0.258932L1.71604 1.81185C1.18257 1.95479 0.86599 2.50312 1.00893 3.03659L3.59712 12.6958C3.74006 13.2293 4.2884 13.5459 4.82187 13.403L5.19894 13.3019L5.16675 13.4221L11.9282 15.2338C12.4617 15.3767 13.01 15.0602 13.153 14.5267L13.1601 14.5001H10.0001C8.6194 14.5001 7.50011 13.3808 7.50011 12.0001C7.50011 10.6194 8.6194 9.50011 10.0001 9.50011H11.023L9.47018 3.70476L14.1629 4.96216L12.9469 9.50011H14.4998L15.7412 4.86743C15.8841 4.33396 15.5675 3.78563 15.0341 3.64269L9.02189 2.03173L8.73634 0.966039ZM9 12.0001C9 12.5524 9.44772 13.0001 10 13.0001H16C16.5523 13.0001 17 12.5524 17 12.0001C17 11.4478 16.5523 11.0001 16 11.0001H10C9.44771 11.0001 9 11.4478 9 12.0001Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};
