import { defineMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { catchAll } from '@sorare/routing';

import { Sport, StoryState } from '@sorare/core/src/__generated__/globalTypes';
import { Dropdown } from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { Binoculars } from '@sorare/core/src/atoms/icons/Binoculars';
import { HomeIcon } from '@sorare/core/src/atoms/icons/HomeIcon';
import { Market } from '@sorare/core/src/atoms/icons/Market';
import { More } from '@sorare/core/src/atoms/icons/More';
import { MyCards } from '@sorare/core/src/atoms/icons/MyCards';
import { PlayFootball } from '@sorare/core/src/atoms/icons/PlayFootball';
import { PlayMLB } from '@sorare/core/src/atoms/icons/PlayMLB';
import { PlayNBA } from '@sorare/core/src/atoms/icons/PlayNBA';
import { Search } from '@sorare/core/src/atoms/icons/Search';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { ManagerTaskTooltip } from '@sorare/core/src/components/onboarding/managerTask/ManagerTaskTooltip';
import { MarketplaceOnboardingTask } from '@sorare/core/src/components/onboarding/managerTask/MarketplaceOnboardingTask';
import {
  ANY_SPORT_MARKET,
  ANY_SPORT_MY_CARDS,
  ANY_SPORT_PLAY,
  ANY_SPORT_SCOUTING_CENTER,
  FOOTBALL_PLAY,
  MLB_HOME,
  NBA_PLAY,
} from '@sorare/core/src/constants/__generated__/routes';
import {
  MarketplaceOnboardingStep,
  useManagerTaskContext,
} from '@sorare/core/src/contexts/managerTask';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';
import { useFeatureFlags } from '@sorare/core/src/hooks/useFeatureFlags';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { navLabels, scoutingCenter } from '@sorare/core/src/lib/glossary';
import {
  generateSportPath,
  matchAnySportPath,
} from '@sorare/core/src/lib/routing/generateSportPath';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { NewNavItem } from '../../NewNavItem';
import { MoreNavItems } from './MoreNavItems';
import { SearchNavItem } from './SearchNavItem';
import { useNavData } from './useNavData';

const Root = styled(Horizontal)`
  width: 100%;
  position: relative;

  & > * {
    flex: 1;
  }
  @media ${laptopAndAbove} {
    flex-direction: column;
    align-items: stretch;
  }
`;
const DropdownContent = styled(Horizontal)`
  width: 200px;
  background-color: var(--c-black);
  padding: var(--half-unit) var(--half-unit) var(--intermediate-unit)
    var(--half-unit);
`;

const PAGES = {
  home: {
    [Sport.BASEBALL]: MLB_HOME,
    [Sport.FOOTBALL]: FOOTBALL_PLAY,
    [Sport.NBA]: NBA_PLAY,
  },
} as const;

const ICONS = {
  [Sport.BASEBALL]: PlayMLB,
  [Sport.FOOTBALL]: PlayFootball,
  [Sport.NBA]: PlayNBA,
} as const;

const itemsToClaim = defineMessage({
  id: 'NavItems.playItemsToClaim',
  defaultMessage: '{count, plural, one {# item} other {# items}} to claim',
});

type Props = { sport: Sport };
export const NewNavItems = ({ sport }: Props) => {
  const {
    flags: { useMlbOffseason = false },
  } = useFeatureFlags();
  const { formatMessage } = useIntl();
  const track = useEvents();
  const location = useLocation();
  const navigate = useNavigate();
  const isLaptopAndAbove = useIsLaptop();
  const { task, setStep } = useManagerTaskContext();
  const navItemsVariant = isLaptopAndAbove ? 'horizontal' : 'vertical';

  const PlayIcon = ICONS[sport];

  const { football, mlb, nba } = useNavData(sport);

  const showLiveDot = {
    [Sport.FOOTBALL]: !!football?.football?.currentManagerHasLiveGames,
    [Sport.BASEBALL]: !!mlb?.so5?.so5Fixture?.mySo5LineupsCount,
    [Sport.NBA]: !!nba?.so5?.so5Fixture?.mySo5LineupsCount,
  }[sport];

  const data = {
    ...(football || {}),
    ...(mlb || {}),
    ...(nba || {}),
  };
  const nbChallengesToClaim =
    data.currentUser?.managerProgressionTasksCount || 0;

  const nbBoxes =
    data.currentUser?.unopenedProbabilisticBundles?.totalCount || 0;

  const unclaimedRewardsCount =
    data.currentUser?.unclaimedSo5Rewards?.length || 0;

  const playBadgeCount = {
    [Sport.FOOTBALL]:
      football?.so5?.myAvailableFixtureStory?.aasmState === StoryState.READY
        ? 1
        : 0,
    [Sport.BASEBALL]: nbChallengesToClaim,
    [Sport.NBA]: 0,
  }[sport];

  const myCardsRewardsCount = nbBoxes + unclaimedRewardsCount;

  return (
    <Root>
      {!useMlbOffseason && sport === Sport.BASEBALL && (
        <NewNavItem
          variant={navItemsVariant}
          icon={() => <HomeIcon size={2} />}
          label={formatMessage(navLabels.home)}
          to={PAGES.home[sport]}
        />
      )}
      <NewNavItem
        variant={navItemsVariant}
        icon={({ isActive }) => <PlayIcon size={2} active={isActive} />}
        label={formatMessage(navLabels.play)}
        to={generateSportPath(ANY_SPORT_PLAY, {
          sport,
        })}
        showLiveDot={sport !== Sport.NBA && showLiveDot}
        badge={playBadgeCount > 0 ? { content: playBadgeCount } : undefined}
      />
      <NewNavItem
        variant={navItemsVariant}
        icon={() => <Binoculars size={2} />}
        label={formatMessage(scoutingCenter.scout)}
        to={generateSportPath(ANY_SPORT_SCOUTING_CENTER, {
          sport,
        })}
        onClick={() =>
          track('Click Scout Nav Item', {
            sport,
          })
        }
      />
      <ManagerTaskTooltip
        TaskComponent={MarketplaceOnboardingTask}
        onClick={() => {
          navigate(
            generateSportPath(ANY_SPORT_MARKET, {
              sport,
            })
          );
          setStep(MarketplaceOnboardingStep.managerSalesLink);
        }}
        disable={!task}
        name={MarketplaceOnboardingStep.menu}
      >
        <NewNavItem
          variant={navItemsVariant}
          icon={({ isActive }) => <Market size={2} active={isActive} />}
          label={formatMessage(navLabels.transferMarket)}
          to={generateSportPath(ANY_SPORT_MARKET, {
            sport,
          })}
        />
      </ManagerTaskTooltip>
      {!isLaptopAndAbove && (
        <SearchNavItem
          variant={navItemsVariant}
          icon={() => <Search size={2} />}
          label={formatMessage(navLabels.search)}
        />
      )}
      <NewNavItem
        variant={navItemsVariant}
        icon={({ isActive }) => <MyCards size={2} active={isActive} />}
        label={formatMessage(navLabels.myCards)}
        to={generateSportPath(ANY_SPORT_MY_CARDS, {
          sport,
        })}
        badge={{
          content: myCardsRewardsCount,
          ariaLabel: formatMessage(itemsToClaim, {
            count: myCardsRewardsCount,
          }),
        }}
        keepSearchParams={
          !!matchAnySportPath(catchAll(ANY_SPORT_MY_CARDS), location.pathname)
        }
      />
      {isLaptopAndAbove && (
        <Dropdown
          forceDesktopBehavior
          side="right"
          gap={24}
          label={({ onClick }) => (
            <button onClick={onClick} type="button">
              <NewNavItem
                icon={() => <More size={2} />}
                label={formatMessage(navLabels.more)}
              />
            </button>
          )}
        >
          {({ closeDropdown }) => (
            <DropdownContent>
              <MoreNavItems onClick={closeDropdown} />
            </DropdownContent>
          )}
        </Dropdown>
      )}
    </Root>
  );
};
