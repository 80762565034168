import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { ChevronDoubleRightBold } from 'atoms/icons/ChevronDoubleRightBold';
import { ChevronRightBold } from 'atoms/icons/ChevronRightBold';
import { Horizontal } from 'atoms/layout/flex';
import { BodyM } from 'atoms/typography';
import { useScreenSize } from 'hooks/device/useScreenSize';
import { laptopAndAbove } from 'style/mediaQuery';

type Props = {
  currentPage: number;
  pages: number;
  onSelect: (index: number) => void;
};

const Input = styled.input`
  display: inline-flex;
  border: 1px solid var(--c-nd-100);
  border-radius: var(--triple-unit);
  min-width: 60px;
  font-size: inherit;
  color: var(--c-white);
  font-weight: bold;
  height: 100%;
  padding: var(--unit) var(--double-unit);

  @media ${laptopAndAbove} {
    padding: var(--unit) var(--triple-unit);
  }
`;
const PaginationWrapper = styled(Horizontal).attrs({ gap: 2, center: true })`
  color: var(--c-nd-600);
  align-items: stretch;
`;

const ChevronLeftBold = styled(ChevronRightBold)`
  transform: rotate(180deg);
`;
const ChevronDoubleLeftBold = styled(ChevronDoubleRightBold)`
  transform: rotate(180deg);
`;

const StyledButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  width: calc(var(--unit) * 5);
  @media ${laptopAndAbove} {
    width: calc(var(--unit) * 6);
  }
`;

export const Pagination = ({ currentPage, pages, onSelect }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const timeoutRef = useRef<any>(null);
  const [page, setPage] = useState(currentPage);
  const { up: istLaptopAndAbove } = useScreenSize('laptop');
  const isValidPage = (newPage: number) => newPage >= 0 && newPage < pages;
  const selectInput = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };
  const onPageChange = (newPage: number) => {
    if (isValidPage(newPage)) {
      onSelect(newPage);
    } else {
      selectInput();
    }
  };

  const buttonSize = istLaptopAndAbove ? 'large' : 'medium';

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  if (pages <= 1) return null;

  return (
    <PaginationWrapper>
      <StyledButton
        color="quaternary"
        size={buttonSize}
        onClick={() => onSelect(0)}
        disabled={currentPage === 0}
      >
        <ChevronDoubleLeftBold />
      </StyledButton>
      <StyledButton
        color="quaternary"
        size={buttonSize}
        onClick={() => onSelect(currentPage - 1)}
        disabled={currentPage <= 0}
      >
        <ChevronLeftBold />
      </StyledButton>
      <Horizontal>
        <Input
          className="text-center"
          min={1}
          max={pages}
          type="number"
          ref={inputRef}
          inputMode="numeric"
          pattern="[0-9]*"
          value={page + 1}
          onFocus={selectInput}
          onChange={e => {
            const newPage = +e.target.value - 1;
            if (newPage <= pages) {
              setPage(newPage);
              if (timeoutRef.current) clearTimeout(timeoutRef.current);
              timeoutRef.current = setTimeout(() => {
                onPageChange(newPage);
              }, 1000);
            }
          }}
        />
        <BodyM as="span" bold>
          <FormattedMessage
            id="pagination.of"
            defaultMessage="of {pages}"
            values={{ pages }}
          />
        </BodyM>
      </Horizontal>
      <StyledButton
        color="quaternary"
        size={buttonSize}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= pages - 1}
      >
        <ChevronRightBold />
      </StyledButton>
      <StyledButton
        color="quaternary"
        size={buttonSize}
        onClick={() => onSelect(pages - 1)}
        disabled={currentPage === pages - 1}
      >
        <ChevronDoubleRightBold />
      </StyledButton>
    </PaginationWrapper>
  );
};
