import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { useWalletDrawerContext } from '@sorare/core/src/contexts/walletDrawer';

import { EthBalance } from './EthBalance';
import { FiatBalance } from './FiatBalance';

const Root = styled(Horizontal).attrs({ gap: 0 })`
  border: 1px solid var(--c-nd-150);
  border-radius: var(--unit);

  & > :first-child {
    border-right: 1px solid var(--c-nd-150);
  }
  & > * {
    flex: 1;
    padding: var(--unit);
    text-align: center;
  }
  &:hover {
    background-color: var(--c-nd-50);
  }

  transition:
    300ms background-color ease-in-out,
    300ms color ease-in-out;
`;

export const MoneyBalances = () => {
  const { toggleDrawer } = useWalletDrawerContext();

  return (
    <Root as="button" onClick={toggleDrawer}>
      <FiatBalance />
      <EthBalance />
    </Root>
  );
};
