import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Dialog } from '@sorare/core/src/components/dialog';

import { CurrencyData } from '../useUserCurrencies';

const Root = styled(Vertical)`
  padding: var(--double-unit);
`;

type Props = {
  open?: boolean;
  onClose?: () => void;
  currencies: CurrencyData[];
};
export const MobileUserCurrenciesDialog = ({
  open,
  onClose,
  currencies,
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Root>
        {currencies.map(({ details }) => details({ onClick: onClose }))}
      </Root>
    </Dialog>
  );
};
