import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import { useIntl } from 'react-intl';

import { Color } from 'atoms/buttons/Button';
import { IconButton } from 'atoms/buttons/IconButton';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useToggleSubscription } from 'hooks/subscriptions/useToggleSubscription';
import { glossary } from 'lib/glossary';

import { FavoriteHeartIcon as FavoriteCardIcon } from '../FavoriteHeartIcon';
import type { FavoriteHeartButton_subscribable } from './__generated__/index.graphql';

interface Props {
  subscribable: FavoriteHeartButton_subscribable;
  small?: boolean;
  color?: Color;
  solid?: boolean;
  onAddToFavoritesSuccess?: () => void;
  onToggled?: () => void;
}

export const FavoriteHeartButton = ({
  subscribable,
  small,
  color = 'transparent',
  solid = true,
  onAddToFavoritesSuccess = () => {},
  onToggled,
}: Props) => {
  const { formatMessage } = useIntl();
  const { currentUser } = useCurrentUserContext();

  const toggleSubscription = useToggleSubscription(
    subscribable.currentUserSubscription,
    subscribable,
    onToggled
  );

  if (!currentUser) return null;

  return (
    <IconButton
      small={small}
      color={color}
      onClick={() => {
        toggleSubscription();

        if (!subscribable.currentUserSubscription) {
          onAddToFavoritesSuccess();
        }
      }}
      aria-label={formatMessage(
        subscribable.currentUserSubscription
          ? glossary.unfollow
          : glossary.follow
      )}
    >
      <FavoriteCardIcon
        hasCurrentUserSubscription={!!subscribable.currentUserSubscription}
        solid={solid}
      />
    </IconButton>
  );
};

FavoriteHeartButton.fragments = {
  subscribable: gql`
    fragment FavoriteHeartButton_subscribable on WithSubscriptionsInterface {
      slug
      currentUserSubscription {
        slug
      }
    }
  ` as TypedDocumentNode<FavoriteHeartButton_subscribable>,
};
