import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { levelUpCardsWithXpCurrencyInput } from '@sorare/core/src/__generated__/globalTypes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useMutation } from '@sorare/core/src/hooks/graphql/useMutation';

import {
  LevelUpCardsWithXpCurrencyMutation,
  LevelUpCardsWithXpCurrencyMutationVariables,
} from './__generated__/useLevelUpCards.graphql';

const LEVEL_UP_CARDS_MUTATION = gql`
  mutation LevelUpCardsWithXpCurrencyMutation(
    $input: levelUpCardsWithXpCurrencyInput!
    $sport: Sport!
  ) {
    levelUpCardsWithXpCurrency(input: $input) {
      cards {
        slug
        grade
        xp
        xpNeededForNextGrade
        xpNeededForCurrentGrade
        lastLeveledUpAt
        nextLevelUpAvailableAt
        power
        powerBreakdown {
          xpBasisPoints
        }
      }
      recomputedBonusSo5Lineups {
        id
        bonusPoints
        bonusRating
        so5Appearances {
          id
          bonus
          bonusRating
          powerBreakdownOrPowerPointsBreakdown {
            ... on PowerBreakdown {
              collectionBasisPoints
              seasonBasisPoints
              xpBasisPoints
            }
            ... on PowerPointsBreakdown {
              collectionPoints
              seasonPoints
              xpPoints
            }
          }
        }
      }
      currentUser {
        slug
        xpBalances: inGameCurrencyBalances(
          sport: $sport
          inGameCurrencies: [
            COMMON_XP
            LIMITED_XP
            RARE_XP
            SUPER_RARE_XP
            UNIQUE_XP
          ]
        ) {
          amount
          currency
          capAlmostReached
        }
      }
      errors {
        code
        message
      }
    }
  }
` as TypedDocumentNode<
  LevelUpCardsWithXpCurrencyMutation,
  LevelUpCardsWithXpCurrencyMutationVariables
>;

export const useLevelUpCards = () => {
  const { sport } = useSportContext();
  const [mutate, { loading }] = useMutation(LEVEL_UP_CARDS_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  const levelUpCards = useCallback(
    async (input: levelUpCardsWithXpCurrencyInput) => {
      return mutate({
        variables: { input, sport },
      });
    },
    [mutate, sport]
  );
  return { levelUpCards, loading };
};
