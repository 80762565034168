import { faEuro } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelS } from '@sorare/core/src/atoms/typography';
import { Dots } from '@sorare/core/src/atoms/ui/Dots';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useFiatBalance } from '@sorare/core/src/hooks/wallets/useFiatBalance';

export const FiatBalance = () => {
  const { availableBalanceWithCurrencySymbol } = useFiatBalance();
  const { currentUser } = useCurrentUserContext();

  const hideBalance = currentUser?.userSettings?.hideBalance;

  return hideBalance ? (
    <Horizontal center>
      <FontAwesomeIcon icon={faEuro} />
      <Horizontal gap={0.5}>
        <Dots count={3} size="small" />
      </Horizontal>
    </Horizontal>
  ) : (
    <LabelS bold>{availableBalanceWithCurrencySymbol}</LabelS>
  );
};
