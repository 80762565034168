import { ComponentType } from 'react';
import styled from 'styled-components';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { Drawer } from '@sorare/core/src/atoms/layout/Drawer';
import { useScreenSize } from '@sorare/core/src/hooks/device/useScreenSize';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';
import { regularMaterial } from '@sorare/core/src/style/utils';

const StyledDrawer = styled(Drawer)`
  width: 100%;
  ${regularMaterial}
  @media ${tabletAndAbove} {
    width: 480px;
  }
`;

type CardOpened = {
  cardSlug: string;
  playerSlug: string;
};

export type PlayerDetails = ComponentType<{
  onClose: () => void;
  playerSlug: string;
  cardSlug: string;
  showCardBonusIndicator?: boolean;
  withBuyingOptions?: {
    rarity: Rarity;
    inSeason: boolean;
    onMarketClick: (playerSlug: string) => void;
  };
}>;

export type Props = {
  cardOpened: CardOpened | null;
  onClose: () => void;
  PlayerDetails: PlayerDetails;
  withBuyingOptions?: {
    rarity: Rarity;
    inSeason: boolean;
    onMarketClick: (playerSlug: string) => void;
  };
};

export const PlayerDrawer = ({
  cardOpened,
  onClose,
  PlayerDetails,
  withBuyingOptions,
}: Props) => {
  const { up: isTabletOrAbove } = useScreenSize('tablet');
  const { up: laptopOrAbove } = useScreenSize('laptop');

  return (
    <StyledDrawer
      open={!!cardOpened}
      side={isTabletOrAbove ? 'right' : 'bottom'}
      onBackdropClick={onClose}
    >
      {cardOpened && (
        <PlayerDetails
          playerSlug={cardOpened.playerSlug}
          cardSlug={cardOpened.cardSlug}
          showCardBonusIndicator={false}
          onClose={onClose}
          {...(!laptopOrAbove && { withBuyingOptions })}
        />
      )}
    </StyledDrawer>
  );
};
