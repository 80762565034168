import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { formatDistanceToNow, isPast, subHours } from 'date-fns';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelS, LabelXS } from '@sorare/core/src/atoms/typography';
import { useWalletDrawerContext } from '@sorare/core/src/contexts/walletDrawer';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { useConversionCredits } from '@sorare/core/src/hooks/useConversionCredits';
import { zeroMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';

import { MarketCreditExpirationGauge } from './MarketCreditExpirationGauge';

const Root = styled(Horizontal)`
  padding: var(--unit);
  border: 1px solid var(--c-nd-150);
  border-radius: var(--unit);

  &.lastDay {
    background: linear-gradient(
      96deg,
      rgba(240, 201, 86, 0.16) 0%,
      rgba(240, 201, 86, 0.03) 100%
    );
  }

  &:hover {
    background-color: var(--c-nd-50);
  }
  transition: 300ms background-color ease-in-out;
`;
const Middle = styled(Vertical)`
  flex: 1;
  text-align: left;
`;
const MarketCreditsAmount = styled(Horizontal).attrs({ center: true })`
  background: var(--c-nd-50);
  border-radius: 50%;
  width: var(--double-unit);
  height: var(--double-unit);
`;

type Props = { sport: Sport };
export const MarketCredits = ({ sport }: Props) => {
  const { toggleDrawer } = useWalletDrawerContext();
  const { conversionCredits } = useConversionCredits({ sport });
  const conversionCredit = conversionCredits?.at(0);
  const { main } = useAmountWithConversion({
    monetaryAmount: conversionCredit?.maxDiscount || zeroMonetaryAmount,
  });

  if (!conversionCredit) {
    return null;
  }

  const { endDate, percentageDiscount, maxDiscount, totalDiscount } =
    conversionCredit;
  const timeLeft = formatDistanceToNow(endDate, { addSuffix: false });

  const lastDay = isPast(subHours(endDate, 24));

  return (
    <Root
      as="button"
      onClick={toggleDrawer}
      className={classNames({ lastDay })}
    >
      <MarketCreditExpirationGauge
        percentage={(maxDiscount.eur / totalDiscount.eur) * 100}
      />
      <Middle gap={0.5}>
        <Horizontal>
          <LabelS bold>
            -
            <FormattedNumber
              value={percentageDiscount}
              style="percent"
              maximumFractionDigits={2}
            />
          </LabelS>
          <svg
            width="2"
            height="2"
            viewBox="0 0 2 2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1" cy="1" r="1" fill="var(--c-nd-150)" />
          </svg>
          <LabelS color="var(--c-nd-600)">{main}</LabelS>
        </Horizontal>
        <LabelS color={lastDay ? 'var(--c-yellow-400)' : 'var(--c-nd-600)'}>
          <FormattedMessage
            id="MarketCredits.timeLeft"
            defaultMessage="{timeLeft} left"
            values={{ timeLeft }}
          />
        </LabelS>
      </Middle>
      <Horizontal gap={0.5}>
        {conversionCredits && conversionCredits.length > 1 && (
          <MarketCreditsAmount>
            <LabelXS>{conversionCredits.length}</LabelXS>
          </MarketCreditsAmount>
        )}
        <FontAwesomeIcon icon={faChevronRight} />
      </Horizontal>
    </Root>
  );
};
