import classNames from 'classnames';
import { ComponentProps, ReactNode } from 'react';
import { To, matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Link, NavLink, catchAll } from '@sorare/routing';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Badge } from '@sorare/core/src/atoms/ui/Badge';
import { LiveDot } from '@sorare/core/src/components/live/LiveDot';

const Root = styled(Vertical)`
  position: relative;
  align-items: center;
  gap: var(--half-unit);
  cursor: pointer;
  color: var(--c-nd-600);
  justify-content: flex-start;

  &&.active {
    color: var(--c-white);
  }

  &.horizontal {
    flex-direction: row;
    gap: var(--unit);
    padding: var(--unit) var(--intermediate-unit);
    border-radius: var(--unit);
    color: var(--c-nd-600);

    &&.active {
      background-color: var(--c-nd-50);
      color: var(--c-white);
    }

    &:hover {
      background-color: rgba(var(--c-rgb-white), 0.04);
      color: var(--c-white);
    }
  }
`;
const RelativeWrapper = styled(Horizontal)`
  position: relative;
`;
const FullWidthSBHorizontal = styled(Horizontal)`
  width: 100%;
  justify-content: center;

  .horizontal & {
    justify-content: space-between;
  }
`;
const PlayLiveDotWrapper = styled.div`
  position: absolute;
  top: calc(-1 * var(--unit));
  right: calc(-1 * var(--double-unit));
`;
const StyledBadge = styled(Badge)`
  .horizontal & {
    margin-right: var(--unit);
  }
`;
const NavLabel = styled.span`
  font: var(--t-label-s);
  .horizontal & {
    font: var(--t-label-l);
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid var(--c-nd-150);
`;

type Props = {
  to?: To;
  icon?: ({ isActive }: { isActive: boolean }) => ReactNode;
  label: string;
  active?: boolean;
  showLiveDot?: boolean;
  badge?: { content: ReactNode; ariaLabel?: string };
  useClassicLink?: boolean;
  variant?: 'horizontal' | 'vertical';
  onClick?: () => void;
  keepSearchParams?: boolean;
  matches?: ComponentProps<typeof NavLink>['matches'];
};
export const NewNavItem = ({
  to,
  icon,
  label,
  active,
  showLiveDot,
  badge,
  useClassicLink,
  variant = 'horizontal',
  ...rest
}: Props) => {
  const location = useLocation();
  const LinkComponent = useClassicLink ? Link : NavLink;

  const isActive = !!(
    to && !!matchPath(catchAll(to.toString()), location.pathname)
  );

  return (
    <Root
      as={to ? LinkComponent : 'div'}
      to={to}
      className={classNames({ active, horizontal: variant === 'horizontal' })}
      state={{
        fromNavItem: true,
      }}
      {...rest}
    >
      {icon?.({ isActive })}
      <FullWidthSBHorizontal>
        <RelativeWrapper>
          <NavLabel>{label}</NavLabel>
          {showLiveDot && (
            <PlayLiveDotWrapper>
              <LiveDot animate size="md" />
            </PlayLiveDotWrapper>
          )}
        </RelativeWrapper>
        {badge && (
          <StyledBadge
            badgeContent={badge.content}
            aria-label={badge.ariaLabel}
          />
        )}
      </FullWidthSBHorizontal>
    </Root>
  );
};
