import styled, { CSSProperties } from 'styled-components';

import marketCredit from '@sorare/core/src/assets/market-credit.svg';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';

const Root = styled(Horizontal).attrs({ center: true })`
  --percentage: 0%;

  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  border-radius: 50%;

  border: 2px solid transparent;
  background:
    conic-gradient(#8f7908 var(--percentage), var(--c-nd-50) var(--percentage))
      padding-box,
    conic-gradient(
        var(--c-yellow-400) var(--percentage),
        var(--c-nd-150) var(--percentage)
      )
      border-box;
`;

type Props = { percentage: number };
export const MarketCreditExpirationGauge = ({ percentage }: Props) => {
  return (
    <Root style={{ '--percentage': `${percentage || 0}%` } as CSSProperties}>
      <img src={marketCredit} alt="" width={16} height={16} />
    </Root>
  );
};
