import classNames from 'classnames';
import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  width: 100%;
  margin: auto;
  display: grid;
  justify-content: center;
  gap: var(--unit);
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(21, 1fr);
  grid-template-areas:
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . . . . . . . . . . . . . . . . . .'
    'D D D D D D . . . . . . . . M M M M M M'
    'D D D D D D . . . . . . . . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    '. . . . . . . G G G G G G . . . . . . .'
    '. . . . . . . G G G G G G . . . . . . .';

  &.slots6 {
    grid-template-rows: repeat(26, 1fr);
    grid-template-areas:
      '. . . . . . . V V V V V V . . . . . . .'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . . . . . . . . E E E E E E'
      '. . . . . . . . . . . . . . . . . . . .'
      '. . . . . . . . . . . . . . . . . . . .'
      'D D D D D D . . . . . . . . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      '. . . . . . . G G G G G G . . . . . . .';
  }

  &.inline {
    padding: 0 var(--half-unit);
    grid-template-rows: repeat(1, 1fr);
    grid-template-areas: 'G G G G D D D D M M M M F F F F E E E E';
    gap: var(--unit);
    transform: scale(1.05);

    &.slots6 {
      grid-template-columns: repeat(18, 1fr);
      grid-template-areas: 'G G G D D D M M M F F F E E E V V V';
    }
  }

  & > * {
    &:nth-child(1) {
      grid-area: G;
    }
    &:nth-child(2) {
      grid-area: D;
    }
    &:nth-child(3) {
      grid-area: M;
    }
    &:nth-child(4) {
      grid-area: F;
    }
    &:nth-child(5) {
      grid-area: E;
    }
    &:nth-child(6) {
      grid-area: V;
    }
  }

  .transitioning + &,
  .standalone & {
    view-transition-name: lineup-field-cards;
    > *:nth-child(1) {
      view-transition-name: lineup-field-card-1;
    }
    > *:nth-child(2) {
      view-transition-name: lineup-field-card-2;
    }
    > *:nth-child(3) {
      view-transition-name: lineup-field-card-3;
    }
    > *:nth-child(4) {
      view-transition-name: lineup-field-card-4;
    }
    > *:nth-child(5) {
      view-transition-name: lineup-field-card-5;
    }
    > *:nth-child(6) {
      view-transition-name: lineup-field-card-6;
    }
  }

  &.smartWidth {
    --card-footer-height: 48px;
    --usable-height: calc(var(--100vh) * var(--height-percentage));
    --card-height: calc(
      ((11 / 24) * var(--usable-height) - var(--card-footer-height))
    );
    --calc-card-width: calc(var(--card-height) * var(--card-aspect-ratio));
    --width: calc(
      3 * var(--calc-card-width) + (2 / 6) * var(--calc-card-width)
    );
    max-width: clamp(300px, var(--width), 100%);
  }
`;

type Props = {
  className?: string;
  fillHeightPercent?: number;
  slots?: number;
  inline?: boolean;
  children?: ReactNode;
};

export const CardsLayout: FC<PropsWithChildren<Props>> = ({
  className,
  fillHeightPercent,
  slots = 5,
  inline = false,
  children,
}) => {
  return (
    <Root
      className={classNames(className, `slots${slots}`, {
        smartWidth: !!fillHeightPercent,
        inline,
      })}
      style={{ '--height-percentage': fillHeightPercent } as CSSProperties}
    >
      {children}
    </Root>
  );
};
