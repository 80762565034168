import classNames from 'classnames';
import { useRef } from 'react';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { SorareLogo } from '@sorare/core/src/atoms/icons/SorareLogo';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { ANY_SPORT_PLAY } from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useIsLoggedIn } from '@sorare/core/src/hooks/auth/useIsLoggedIn';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { GlobalStyles } from './GlobalStyles';
import { NewNavItems } from './NewNavItems';
import { SearchBar } from './SearchBar';
import { SportSelector } from './SportSelector';
import { UserCurrencies } from './UserCurrencies';
import { useBottomBarScroll } from './useBottomBarScroll';

const MIN_BOTTOM_PADDING_MOBILE_APP = 16;

const Root = styled(Vertical).attrs({ gap: 0 })`
  position: sticky;
  z-index: 2;
  bottom: 0;

  &.hideBottomBar {
    transform: translateY(var(--navbar-height-mobile));
  }
  transition: 300ms transform ease-in-out;

  @media ${laptopAndAbove} {
    position: sticky;
    top: 0;
    height: 100vh;
    width: 240px;
  }
`;
const Nav = styled(Horizontal)`
  width: 100%;
  height: var(--navbar-height-mobile);
  gap: 0;
  background-color: var(--c-black);
  border-top: 1px solid var(--c-nd-50);

  @media ${laptopAndAbove} {
    flex: 1;
    flex-direction: column;
    gap: var(--double-unit);
    border-top: none;
    border-right: 1px solid var(--c-nd-150);
    padding: var(--intermediate-unit);
  }
`;
const FullWidthSBHorizontal = styled(SBHorizontal)`
  width: 100%;
`;
const LogoWrapper = styled(Horizontal)`
  max-width: 100px;
  height: 40px;
`;
const PortalReceiver = styled(Vertical).attrs({ gap: 0 })`
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
`;
const CurrenciesWrapper = styled.div`
  width: 100%;
  margin-top: auto;
`;

export const NewAppNavigation = () => {
  const { sport } = useSportContext();
  const navigationRef = useRef<HTMLElement>(null);
  const isLaptopAndAbove = useIsLaptop();
  const loggedIn = useIsLoggedIn();
  const { isMobileApp, hideBottomNavBar } = useIsMobileApp();
  const hideBottomBar = useBottomBarScroll(navigationRef);

  if (!loggedIn) {
    return null;
  }

  if (isMobileApp && hideBottomNavBar) {
    return (
      <Root>
        <GlobalStyles noHeight />
        <PortalReceiver id="above-bottom-bar-portal" />
      </Root>
    );
  }

  return (
    <>
      <GlobalStyles
        noHeight={isLaptopAndAbove || hideBottomBar}
        minSafeAreaInsetBottom={isMobileApp ? MIN_BOTTOM_PADDING_MOBILE_APP : 0}
      />
      <Root className={classNames({ hideBottomBar })}>
        {!isLaptopAndAbove && <PortalReceiver id="above-bottom-bar-portal" />}
        <Nav as="nav" ref={navigationRef}>
          {isLaptopAndAbove && (
            <>
              <FullWidthSBHorizontal>
                <LogoWrapper
                  as={Link}
                  to={generateSportPath(ANY_SPORT_PLAY, { sport })}
                >
                  <SorareLogo />
                </LogoWrapper>
                <SportSelector />
              </FullWidthSBHorizontal>
              <SearchBar />
            </>
          )}
          <NewNavItems sport={sport} />
          {isLaptopAndAbove && (
            <CurrenciesWrapper>
              <UserCurrencies />
            </CurrenciesWrapper>
          )}
        </Nav>
      </Root>
    </>
  );
};
