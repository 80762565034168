import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FC, ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import {
  InGameCurrency,
  Rarity,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Boost } from '@sorare/core/src/atoms/icons/Boost';
import { Energy } from '@sorare/core/src/atoms/icons/Energy';
import { Shards } from '@sorare/core/src/atoms/icons/Shards';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import {
  BodyM,
  HeadlineM,
  LabelM,
  LabelS,
} from '@sorare/core/src/atoms/typography';
import {
  ANY_SPORT_MY_CARDS_ESSENCE,
  ANY_SPORT_MY_CARDS_ESSENCE_TYPE,
} from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useGetMyCardsBoostLink } from '@sorare/core/src/hooks/my-cards/useGetMyCardsBoostLink';
import { formatScarcity } from '@sorare/core/src/lib/cards';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';
import { inGameCurrencyToRarity } from '@sorare/core/src/lib/scarcity';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

export enum InGameCurrencyAgreggated {
  ENERGY = 'ENERGY',
  XP = 'XP',
  SHARDS = 'SHARDS',
}

export type CurrencyData = {
  id: InGameCurrencyAgreggated;
  icon: ReactNode;
  amount?: number;
  details: FC;
  visibleOnSports: Sport[];
  expanded?: boolean;
  onClick?: () => void;
};

type DetailsProps = { onClick?: () => void };

const SelectedCurrencyWrapper = styled(Vertical)`
  padding: var(--double-unit) var(--unit) var(--unit) var(--unit);
  border: 1px solid var(--c-nd-150);
  border-radius: var(--intermediate-unit);
`;
const RaritiesWrapper = styled(Horizontal)`
  align-items: flex-end;
  @media ${laptopAndAbove} {
    flex-direction: column;
    gap: 0;
  }
`;
const RarityWrapper = styled(Vertical)`
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  border-radius: var(--unit);
  padding: var(--half-unit) var(--unit);

  &:hover {
    background-color: var(--c-nd-50);
  }

  @media ${laptopAndAbove} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const useUserCurrencies = (
  expandedCurrencies?: InGameCurrencyAgreggated[]
): CurrencyData[] => {
  const navigate = useNavigate();
  const { sport } = useSportContext();
  const getMyCardsBoostLink = useGetMyCardsBoostLink();
  const { currentUser } = useCurrentUserContext();
  const { energyBalance, xpBalances, shardsChests } = currentUser || {};

  const filteredXpBalances =
    currentUser?.betaTesterFootballCommonCardsGame || sport !== Sport.FOOTBALL
      ? xpBalances
      : xpBalances?.filter(
          balance => balance.currency !== InGameCurrency.COMMON_XP
        );
  const totalEnergyBalance = energyBalance?.reduce(
    (sum, { amount }) => sum + amount,
    0
  );
  const totalShards = shardsChests?.reduce(
    (acc, chest) => acc + chest.cardShardsCount,
    0
  );
  const firstNonZeroBalance = (filteredXpBalances || []).find(
    ({ amount }) => amount > 0
  );
  const firstNonZeroBalanceRarity = firstNonZeroBalance?.currency
    ? inGameCurrencyToRarity[firstNonZeroBalance.currency] || Rarity.limited
    : Rarity.limited;
  const totalXPBalance = filteredXpBalances?.reduce(
    (sum, { amount }) => sum + amount,
    0
  );

  return [
    {
      id: InGameCurrencyAgreggated.XP,
      icon: <Boost size={2} color="var(--c-rivals-friendlies)" />,
      amount: totalXPBalance,
      details: ({ onClick }: DetailsProps) => (
        <SelectedCurrencyWrapper>
          <Vertical gap={0.5} center>
            <Boost size={2} color="var(--c-rivals-friendlies)" />
            <LabelM brand25 color="var(--c-rivals-friendlies)">
              <FormattedMessage
                id="XpBalances.xpReserve"
                defaultMessage="XP Reserve"
              />
            </LabelM>
            <HeadlineM brand25>
              <FormattedNumber value={totalXPBalance || 0} />
            </HeadlineM>
          </Vertical>
          <RaritiesWrapper>
            {filteredXpBalances?.map(
              ({ currency, amount, capAlmostReached }) => {
                const rarity = inGameCurrencyToRarity[currency];
                if (!rarity) return null;

                return (
                  <RarityWrapper
                    key={currency}
                    as={Link}
                    to={getMyCardsBoostLink({
                      rarity,
                      sport,
                    })}
                    onClick={onClick}
                  >
                    <LabelS>{formatScarcity(rarity)}</LabelS>
                    <Horizontal>
                      <BodyM brand25>
                        <FormattedNumber value={amount} />
                      </BodyM>
                      {capAlmostReached && (
                        <FontAwesomeIcon
                          size="xs"
                          color="var(--c-yellow-400)"
                          icon={faTriangleExclamation}
                        />
                      )}
                    </Horizontal>
                  </RarityWrapper>
                );
              }
            )}
          </RaritiesWrapper>
        </SelectedCurrencyWrapper>
      ),
      visibleOnSports: [Sport.FOOTBALL, Sport.NBA, Sport.BASEBALL],
      expanded: expandedCurrencies?.includes(InGameCurrencyAgreggated.XP),
      onClick: () =>
        navigate(
          getMyCardsBoostLink({
            rarity: firstNonZeroBalanceRarity,
            sport,
          })
        ),
    },
    {
      id: InGameCurrencyAgreggated.SHARDS,
      icon: <Shards size={2} color="var(--c-rivals-win)" />,
      amount: totalShards,
      details: ({ onClick }: DetailsProps) => (
        <SelectedCurrencyWrapper>
          <Vertical gap={0.5} center>
            <Shards size={2} color="var(--c-rivals-win)" />
            <LabelM brand25 color="var(--c-rivals-win)">
              <FormattedMessage
                id="useUserCurrencies.essence"
                defaultMessage="Essence"
              />
            </LabelM>
            <HeadlineM brand25>
              <FormattedNumber value={totalShards || 0} />
            </HeadlineM>
          </Vertical>
          <RaritiesWrapper>
            {shardsChests?.map(({ cardShardsCount, rarity }) => {
              return (
                <RarityWrapper
                  key={rarity}
                  as={Link}
                  to={generateSportPath(ANY_SPORT_MY_CARDS_ESSENCE_TYPE, {
                    params: { type: rarity },
                    sport,
                  })}
                  onClick={onClick}
                >
                  <LabelS>{formatScarcity(rarity)}</LabelS>
                  <BodyM brand25>
                    <FormattedNumber value={cardShardsCount} />
                  </BodyM>
                </RarityWrapper>
              );
            })}
          </RaritiesWrapper>
        </SelectedCurrencyWrapper>
      ),
      visibleOnSports: [Sport.FOOTBALL, Sport.NBA, Sport.BASEBALL],
      expanded: expandedCurrencies?.includes(InGameCurrencyAgreggated.SHARDS),
      onClick: () =>
        navigate(generateSportPath(ANY_SPORT_MY_CARDS_ESSENCE, { sport })),
    },
    {
      id: InGameCurrencyAgreggated.ENERGY,
      icon: <Energy size={2} color="var(--c-yellow-300)" />,
      amount: totalEnergyBalance,
      details: () => (
        <SelectedCurrencyWrapper>
          <Vertical gap={0.5} center>
            <Energy size={2} color="var(--c-yellow-300)" />
            <LabelM brand25 color="var(--c-yellow-300)">
              <FormattedMessage
                id="useUserCurrencies.energy"
                defaultMessage="Energy"
              />
            </LabelM>
            <HeadlineM brand25>
              <FormattedNumber value={totalEnergyBalance || 0} />
            </HeadlineM>
          </Vertical>
          <RaritiesWrapper gap={0}>
            {energyBalance?.map(({ currency, amount, capAlmostReached }) => {
              const rarity = inGameCurrencyToRarity[currency];
              if (!rarity) return null;

              return (
                <RarityWrapper key={currency}>
                  <LabelS>{formatScarcity(rarity)}</LabelS>
                  <Horizontal>
                    <BodyM brand25>
                      <FormattedNumber value={amount} />
                    </BodyM>
                    {capAlmostReached && (
                      <FontAwesomeIcon
                        size="xs"
                        color="var(--c-yellow-400)"
                        icon={faTriangleExclamation}
                      />
                    )}
                  </Horizontal>
                </RarityWrapper>
              );
            })}
          </RaritiesWrapper>
        </SelectedCurrencyWrapper>
      ),
      visibleOnSports: [Sport.NBA, Sport.BASEBALL],
      expanded: expandedCurrencies?.includes(InGameCurrencyAgreggated.ENERGY),
    },
  ];
};
