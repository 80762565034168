import classNames from 'classnames';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { createGlobalStyle, css } from 'styled-components';

import { NavLink } from 'atoms/buttons/NavLink';
import { AddFriends } from 'atoms/icons/AddFriends';
import { StarBall } from 'atoms/icons/StarBall';
import { Vertical } from 'atoms/layout/flex';
import { Badge } from 'atoms/ui/Badge';
import { useDefaultSportPages } from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import {
  MarketplaceOnboardingStep,
  useManagerTaskContext,
} from 'contexts/managerTask';
import { useSportContext } from 'contexts/sport';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import { useIsMobile } from 'hooks/device/useIsMobile';
import { useInviteLink } from 'hooks/useInviteLink';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { useIsPWA } from 'hooks/useIsPWA';
import { navLabels } from 'lib/glossary';
import { useShowBottomBarNav } from 'routing/MultiSportBottomNavBar/useShowBottomBarNav';

import { FooterMenu } from '../FooterMenu';
import { NavItem } from '../NavItem';
import { NavItems } from '../NavItems';

const APP_BAR_MOBILE_HEIGHT = 57;
const MIN_BOTTOM_PADDING_MOBILE_APP = 16;

const GlobalStyle = createGlobalStyle<{
  noHeight: boolean;
  minSafeAreaInsetBottom?: number;
}>`
  :root {
    ${({ minSafeAreaInsetBottom = 0 }) => css`
      --safe-area-inset-bottom: max(
        ${minSafeAreaInsetBottom}px,
        env(safe-area-inset-bottom, 0px)
      );
    `}
    --bottom-bar-height-mobile: calc(${APP_BAR_MOBILE_HEIGHT}px + var(--safe-area-inset-bottom));
    ${({ noHeight }) =>
      noHeight &&
      css`
        --bottom-bar-height-mobile: 0px;
      `}
  }
`;

const Root = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 2;
`;
const Container = styled.div`
  --navbar-width-desktop: 80px;
  width: 100%;
  transition: 0.3s ease transform;
  &.hide {
    /* account for 2px badge overflow */
    transform: translateY(calc(var(--nav-height, 100%) + 2px));
  }
  &.sideNavBar {
    position: sticky;
    top: 0;
    bottom: unset;
    height: 100vh;
    width: var(--navbar-width-desktop);
    overflow: hidden;
    &.hide {
      transform: unset;
    }
  }
`;

const Nav = styled.nav`
  position: relative;
  background: var(--c-black);
  border-top: 1px solid var(--c-nd-100);
  /* let some space for the bottom home swipe bar */
  padding-bottom: var(--safe-area-inset-bottom);
  .sideNavBar & {
    display: flex;
    flex-direction: column;
    border-top: unset;
    border-right: 1px solid var(--c-nd-100);
    height: 100vh;
    padding-bottom: 0;
  }
`;

const List = styled.div`
  display: flex;
  .sideNavBar & {
    flex-direction: column;
    height: inherit;
  }
`;

const PortalReceiver = styled(Vertical).attrs({ gap: 0 })`
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: var(--safe-area-inset-bottom);
`;

const SorareNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: var(--quadruple-unit);
    height: var(--quadruple-unit);
  }
  .sideNavBar & {
    padding: var(--double-unit) 0 var(--quadruple-unit);
  }
`;

const ReferralNavItem = styled(NavItem)`
  & > svg {
    width: 20px;
  }
`;

export const MobileAppBottomPortalReceiver = styled(PortalReceiver)`
  padding-bottom: var(--double-unit);
  &.isAndroidApp {
    padding-bottom: 0;
  }
`;

export const AppNavigation = forwardRef<HTMLDivElement>(
  (props, forwardedRef) => {
    const navigationRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef({ oldScroll: 0 });
    const isPwa = useIsPWA();
    const [hide, setHide] = useState(false);
    const showBottomBarNav = useShowBottomBarNav();
    const isLoggedIn = useIsLoggedIn();
    const { sport } = useSportContext();
    const { formatMessage } = useIntl();
    const defaultSportPages = useDefaultSportPages();
    const { isMobileApp, hideBottomNavBar } = useIsMobileApp();
    const { step } = useManagerTaskContext();
    const isMobile = useIsMobile();
    const { currentUser } = useCurrentUserContext();

    const inviteLink = useInviteLink();

    useEffect(() => {
      if (!showBottomBarNav || isPwa) {
        return () => {};
      }
      const onScroll = () => {
        const { scrollingElement } = document;
        if (
          !scrollingElement ||
          !navigationRef.current ||
          isMobileApp ||
          step === MarketplaceOnboardingStep.menu
        ) {
          return;
        }

        const { scrollTop } = scrollingElement;
        const isScrollingDown = scrollRef.current.oldScroll < scrollTop;
        const hasReachedTop = scrollTop <= 0;
        const hasReachedBottom =
          scrollTop + window.innerHeight >= document.body.offsetHeight;

        scrollRef.current.oldScroll = scrollTop;
        setHide(isScrollingDown && !hasReachedBottom && !hasReachedTop);
      };

      window.document.addEventListener('scroll', onScroll);
      return () => {
        window.document.removeEventListener('scroll', onScroll);
      };
    }, [showBottomBarNav, isMobileApp, step, isPwa]);

    if (!isLoggedIn) {
      return null;
    }

    if (isMobileApp && hideBottomNavBar) {
      return (
        <Root>
          <GlobalStyle noHeight />
          <Container
            className={classNames({ hide: false, sideNavBar: false })}
            ref={forwardedRef}
          >
            <PortalReceiver id="above-bottom-bar-portal" />
          </Container>
        </Root>
      );
    }

    return (
      <>
        <GlobalStyle
          noHeight={!showBottomBarNav || hide}
          minSafeAreaInsetBottom={
            isMobileApp ? MIN_BOTTOM_PADDING_MOBILE_APP : 0
          }
        />
        <Root>
          <Container
            className={classNames({ hide, sideNavBar: !showBottomBarNav })}
            ref={forwardedRef}
            style={
              {
                '--nav-height': showBottomBarNav
                  ? `${navigationRef.current?.offsetHeight || 0}px`
                  : '0px',
              } as React.CSSProperties
            }
          >
            <PortalReceiver id="above-bottom-bar-portal" />
            <Nav ref={navigationRef}>
              <List>
                {!showBottomBarNav && (
                  <SorareNavLink
                    title={formatMessage(navLabels.home)}
                    to={defaultSportPages[sport]}
                  >
                    <StarBall color="var(--c-white)" />
                  </SorareNavLink>
                )}
                <NavItems sport={sport} />
                {!isMobile && (
                  <ReferralNavItem to={inviteLink}>
                    {({ isActive, isPending }) => (
                      <>
                        <Badge
                          badgeContent={
                            currentUser?.unclaimedReferralRewardsCount
                          }
                        >
                          <AddFriends active={isActive || isPending} />
                        </Badge>
                        <FormattedMessage {...navLabels.inviteFriends} />
                      </>
                    )}
                  </ReferralNavItem>
                )}
                {!isMobile && !showBottomBarNav && <FooterMenu />}
              </List>
            </Nav>
          </Container>
        </Root>
      </>
    );
  }
);

AppNavigation.displayName = 'AppNavigation';
